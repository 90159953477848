import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Markdown from 'react-markdown';
import {
  Flex, Box, Text,
} from 'rebass/styled-components';
import LogoGithub from 'react-ionicons/lib/LogoGithub';
import LogoLinkedin from 'react-ionicons/lib/LogoLinkedin';
import IosPinOutline from 'react-ionicons/lib/IosPinOutline';
import Container from '../components/shared/Container';
import SEO from '../components/shared/SEO';
import Clickable from '../components/shared/Clickable';
import mario from '../images/mario.png';

const About = injectIntl(({ intl }) => (
  <div>
    <SEO title={`Tugboat Coding - ${intl.formatMessage({ id: 'about.heading' })}`} />
    <Box my="32px">
      <h1><FormattedMessage id="about.heading" /></h1>
    </Box>
    <Box pb="24px">
      <Flex flexDirection={['column', 'row']} alignItems="center">
        <img alt="Mario Hayashi" style={{ borderRadius: 60 }} src={mario} width="120px" />
        <Box pt={['16px', '0px']} pl={['0px', '24px']}>
          <Text textAlign={['center', 'left']} fontWeight="bold">Mario Hayashi</Text>
          <Text textAlign={['center', 'left']}>Director</Text>
          <Flex
            flexDirection={['row', 'column']}
            alignItems={['center', 'flex-start']}
            justifyContent={['center', 'flex-start']}
          >
            <Box pt="8px" pr={['16px', '0px']}>
              <Flex alignItems="baseline">
                <Box pr="4px">
                  <Clickable>
                    <a href="https://github.com/logicalicy" target="_blank" rel="noreferrer">
                      <LogoGithub fontSize="24px" />
                    </a>
                  </Clickable>
                </Box>
                <Clickable>
                  <a href="https://www.linkedin.com/in/mariohayashi/" target="_blank" rel="noreferrer">
                    <LogoLinkedin fontSize="24px" />
                  </a>
                </Clickable>
              </Flex>
            </Box>
            <Box ml="-2px">
              <Flex alignItems="center" justifyContent={['center', 'flex-start']}>
                <IosPinOutline fontSize="16px" />
                <Box pl="4px"><Text fontSize="14px">London, UK</Text></Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
    <Box mb="32px">
      <Markdown source={intl.formatMessage({ id: 'about.summary.body' })} />
    </Box>
    <Box mb="24px">
      <h2>{<FormattedMessage id="about.full_stack.heading" />}</h2>
    </Box>
    <Box mb="32px">
      <Markdown source={intl.formatMessage({ id: 'about.full_stack.body' })} />
    </Box>
    <Box mb="24px">
      <h2><FormattedMessage id="about.product.heading" /></h2>
    </Box>
    <Box mb="32px">
      <Markdown source={intl.formatMessage({ id: 'about.product.body' })} />
    </Box>
    <Box mb="24px">
      <h2><FormattedMessage id="about.user.heading" /></h2>
    </Box>
    <Box mb="32px">
      <Markdown source={intl.formatMessage({ id: 'about.user.body' })} />
    </Box>
  </div>
));

const AboutPage = () => (
  <Container>
    <About />
  </Container>
);

export default AboutPage;
